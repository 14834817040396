import { Info, Theme, TooltipColor, getStoryblokTheme } from '@dtk/ui-components';
import { SbBlokData } from '@storyblok/react';
import classNames from 'classnames';
import { DynamicComponent } from '../../02_composite/DynamicComponent';

interface ClubInfoCardProps {
  blok: {
    theme: Theme;
    title: string;
    tooltipText: string;
    cards: SbBlokData[];
  };
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  data: Record<string, any>;
}

export function ClubInfoCard({ blok, data }: ClubInfoCardProps) {
  const { title, tooltipText, cards } = blok;
  const { primaryTextColorComplementary, infoIconColor, infoIconTooltipColorVariant } = getStoryblokTheme(blok?.theme);
  const DTK_INDEX: number = data?.dtkData?.dtkIndex;

  return (
    <div className={classNames('col-span-12 flex flex-col', DTK_INDEX > 0 && 'lg:col-span-6')}>
      <div className="w-full items-center rounded-t-lg bg-white text-lg">
        <div className="mx-6 mt-6 mb-2.5 flex justify-between">
          <div className={classNames('font-semibold', `text-${primaryTextColorComplementary || 'navy'}`)}>{title}</div>
          <div className={infoIconColor ? `text-${primaryTextColorComplementary}` : 'navy'}>
            <Info content={tooltipText} variant="tooltip" colorVariant={infoIconTooltipColorVariant as TooltipColor} />
          </div>
        </div>
        <div className="space-y-3">
          {cards && cards.map((component, index) => <DynamicComponent key={index} blok={component} data={data} />)}
        </div>
      </div>
    </div>
  );
}
