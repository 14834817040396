// dummy
import { ContactCard, ContactCardProps } from '@dtk/ui-components';

export const fallBackContact: ContactCardProps = {
  firstname: 'Christian',
  lastname: 'Marz',
  eMail: 'info@deutsche-teilkauf.de',
  phone: '+49 (0) 221 718 2 9100',
  pictureUrl: 'https://a.storyblok.com/f/148087/1032x630/30bcd66386/marz.png',
};

type ContactCardFallbackProps = {
  blok?: {
    firstname: string;
    lastname: string;
    eMail: string;
    phone: string;
    pictureUrl: string;
  };
  primaryTextColor?: string;
  primaryBgColor?: string;
  secondaryBgColor?: string;
  svgPrimaryColor?: string;
};

export function ContactCardFallback({
  blok,
  primaryTextColor,
  primaryBgColor,
  secondaryBgColor,
  svgPrimaryColor,
}: ContactCardFallbackProps) {
  return (
    <ContactCard
      firstname={blok?.firstname || fallBackContact.firstname}
      lastname={blok?.lastname || fallBackContact.lastname}
      eMail={blok?.eMail || fallBackContact.eMail}
      phone={blok?.phone || fallBackContact.phone}
      pictureUrl={blok?.pictureUrl || fallBackContact.pictureUrl}
      primaryTextColor={primaryTextColor}
      primaryBgColor={primaryBgColor}
      secondaryBgColor={secondaryBgColor}
      svgPrimaryColor={svgPrimaryColor}
    />
  );
}
