import { PricingTrend } from '@dtk/query';

export const getValue = (value: string | number, placeholder: string, returnedValue: string | undefined) => {
  if (value === undefined || value === null || value === '') {
    return placeholder;
  }

  return returnedValue;
};

export const getTrendCurrentYear = (trendData: PricingTrend) => {
  if (!trendData) {
    return null;
  }

  const currentYear = new Date().getFullYear();

  const trendCurrentYear =
    trendData?.past?.find((trend) => trend.year === currentYear.toString()) ||
    trendData?.future?.find((trend) => trend.year === currentYear.toString());

  return trendCurrentYear;
};
