import { StageContextData, StageStatus } from '@dtk/query';
import {
  CardSize,
  InfoCard,
  QueryStatusCard,
  StepStatus,
  StepsCarousel,
  Theme,
  TooltipColor,
  getStoryblokTheme,
} from '@dtk/ui-components';

import { useState } from 'react';
import {
  SlideOverBodyProps,
  SlideOverCurrentActivity,
  calculatePercent,
  contentStatus,
  fallBack,
  getSliderOverBodyContent,
  mapStageToSteps,
} from './TeilkaufStatusInfo';

export function ProcessStatusCard({ blok, data }: ProcessStatusCardProps) {
  const { title, tooltipText } = blok;
  const { stageContext } = data;

  const [open, setOpen] = useState(false);
  const [sliderBodyProps, setSliderBodyProps] = useState<SlideOverBodyProps>(fallBack);

  if (!stageContext?.status) {
    return (
      <QueryStatusCard title={title} tooltipText={tooltipText} state={'noDataAvailable'} cardSize={CardSize.SMALL} />
    );
  }

  const steps = mapStageToSteps(stageContext.interestedInPartialSale ? stageContext.status : 'Club');

  const percent = calculatePercent(steps);

  const handleCurrentClick = () => {
    const body = getSliderOverBodyContent(stageContext.currentActivity);
    setSliderBodyProps(body);
    setOpen(!open);
  };

  const handleClick = (status: string) => {
    setSliderBodyProps(contentStatus[status]);
    setOpen(!open);
  };

  const {
    primaryTextColor,
    primaryBgColor,
    secondaryTextColor,
    secondaryBgColor,
    tertiaryTextColor,
    infoIconColor,
    infoIconTooltipColorVariant,
  } = getStoryblokTheme(blok?.theme);

  return (
    <div
      className="xs:col-span-12 text-navy col-span-12 rounded-lg md:col-span-6 lg:col-span-6 xl:col-span-3"
      id="dtk-status"
    >
      <SlideOverCurrentActivity isOpen={open} setOpen={setOpen} sliderBodyProps={sliderBodyProps} />

      <InfoCard
        title={title}
        containerStyle="shadow-lg rounded-lg lg:min-h-[368px] md:min-h-[320px] xs:min-h-[322px] h-full"
        tooltipText={tooltipText}
        tooltipColorVariant={infoIconTooltipColorVariant as TooltipColor}
        titleColor={primaryTextColor}
        backgroundColor={primaryBgColor}
        tooltipIconColor={infoIconColor}
      >
        <StepsCarousel
          svgPrimaryColor={secondaryTextColor}
          svgSecondaryColor={tertiaryTextColor}
          stepNumberColor={secondaryBgColor}
          steps={steps}
          stepTextColor={primaryTextColor}
          footerColor={tertiaryTextColor}
          stepInfoIconColor={infoIconColor}
          handleStep={handleClick}
          handleCurrent={handleCurrentClick}
          percent={percent}
        />
      </InfoCard>
    </div>
  );
}

export interface ProcessStatusCardProps {
  blok: {
    tooltipText: string;
    title: string;
    theme: Theme;
  };
  data: { stageContext: StageContextData };
}

export interface ProcessStatusStep {
  id: number;
  key: StageStatus;
  name: string;
  description: string;
  status: StepStatus;
}
