import { PricingTrendChart } from '@dtk/estate-overview';
import { Immobilie, PricingTrend } from '@dtk/query';
import { CardSize, InfoCard, QueryStatusCard, Theme, TooltipColor, getStoryblokTheme } from '@dtk/ui-components';
import ParentSize from '@visx/responsive/lib/components/ParentSize';

import { ReactNode } from 'react';
import { calculateNumTicksAxisBottom, getPastAndFutureYears, mapToChartData } from './utils';

export const MarketPriceTrendCard = ({ blok, data }: MarketPriceTrendCardProps) => {
  const { title, tooltipText } = blok;
  const { isLead, estateData, estateError, estateLoading, trendData, trendError, trendLoading } = data;

  if (isLead) {
    return null;
  }

  if (trendLoading || estateLoading) {
    return (
      <MarketPriceTrendCardWrapper>
        <QueryStatusCard title={title} tooltipText={tooltipText} isLoading={true} cardSize={CardSize.MEDIUM} />
      </MarketPriceTrendCardWrapper>
    );
  }

  if (trendError || estateError) {
    return (
      <MarketPriceTrendCardWrapper>
        <QueryStatusCard title={title} tooltipText={tooltipText} state={'tryAgainLater'} cardSize={CardSize.MEDIUM} />
      </MarketPriceTrendCardWrapper>
    );
  }

  const wohnflaeche = estateData?.basisdaten?.wohnflaeche;
  const address = estateData?.address;
  const trends = trendData;

  if (!trends || JSON.stringify(trends) === '{}' || !wohnflaeche) {
    return (
      <MarketPriceTrendCardWrapper>
        <QueryStatusCard title={title} tooltipText={tooltipText} state={'noDataAvailable'} />
      </MarketPriceTrendCardWrapper>
    );
  }

  const { pastYearsFromCurrent, futureYearsFromCurrent } = getPastAndFutureYears(trends);

  const trendsWithoutDuplicates = {
    ...trends,
    past: pastYearsFromCurrent,
    future: futureYearsFromCurrent?.slice(1),
  };

  const {
    primaryTextColor,
    primaryBgColor,
    secondaryTextColor,
    tertiaryTextColor,
    infoIconColor,
    infoIconTooltipColorVariant,
  } = getStoryblokTheme(blok?.theme);

  return (
    <MarketPriceTrendCardWrapper>
      <InfoCard
        title={title}
        titleColor={primaryTextColor}
        tooltipText={tooltipText}
        tooltipColorVariant={infoIconTooltipColorVariant as TooltipColor}
        tooltipIconColor={infoIconColor}
        containerStyle="xs:pb-2 lg:pb-0 rounded-lg"
        backgroundColor={primaryBgColor}
      >
        <ParentSize>
          {({ width, height }) => (
            <PricingTrendChart
              axisValuesColor={tertiaryTextColor}
              trendLineColor={secondaryTextColor}
              height={height}
              width={width}
              street={address?.street}
              trends={mapToChartData(trendsWithoutDuplicates, wohnflaeche)}
              calculateNumTicksAxisBottom={calculateNumTicksAxisBottom}
            />
          )}
        </ParentSize>
      </InfoCard>
    </MarketPriceTrendCardWrapper>
  );
};

export interface MarketPriceTrendCardProps {
  blok: MarketPriceTrendCardBlok;
  data: MarketPriceCardData;
}

export interface MarketPriceTrendCardBlok {
  component: string;
  title: string;
  tooltipText: string;
  _uid: string;
  theme: Theme;
}

export interface MarketPriceCardData {
  estateData: Immobilie;
  trendData: PricingTrend;
  isLead: boolean;
  estateError: boolean;
  trendError: boolean;
  estateLoading: boolean;
  trendLoading: boolean;
}

interface MarketPriceTrendCardWrapperProps {
  children: ReactNode;
}

const MarketPriceTrendCardWrapper = ({ children }: MarketPriceTrendCardWrapperProps) => (
  <div className="col-span-12 flex min-h-[460px] flex-col lg:col-span-6 lg:min-h-[310px]">{children}</div>
);
