import { InfoCard, Spinner, Theme, TooltipColor, Visible, getStoryblokTheme } from '@dtk/ui-components';

import { ColorPickerBlok } from '@dtk/types';
import classNames from 'classnames';

interface Card {
  _uid: string;
  title: string;
  titleColor: ColorPickerBlok;
  backgroundColor?: ColorPickerBlok;
  value: number;
  valueIcon?: React.ReactNode;
  footer: string;
  tooltipText: string;
  tooltipColorVariant: TooltipColor;
  tooltipIconColor: ColorPickerBlok;
  isError: boolean;
  isLoading: boolean;
  theme: Theme;
}

export interface DoubleInfoCardWithValueProps {
  blok: {
    cards: Card[];
    isLead: boolean;
    isLoading: boolean;
  };
  data: { valuePlaceholder: string };
}

const getContainerStyle = (card: Card, index: number) =>
  classNames(
    'flex-1 xs:min-h-[161px] md:min-h-[178px] lg:min-h-[184px] md:min-h-[178px] sx:min-h-[179px]',
    index === 0 ? 'rounded-t-lg' : 'rounded-b-lg'
  );

export function DoubleInfoCardWithValue({ blok, data }: DoubleInfoCardWithValueProps) {
  return (
    <div className="xs:col-span-12 col-span-12 flex flex-col rounded-lg shadow-lg md:col-span-6 lg:col-span-6 xl:col-span-3">
      {blok.cards.map((card, index) => {
        const {
          primaryTextColor,
          primaryBgColor,
          secondaryTextColor,
          tertiaryTextColor,
          infoIconColor,
          infoIconTooltipColorVariant,
        } = getStoryblokTheme(card?.theme);

        return (
          <InfoCard
            key={card._uid}
            title={card.title}
            titleColor={primaryTextColor}
            tooltipText={card.tooltipText}
            tooltipColorVariant={infoIconTooltipColorVariant as TooltipColor}
            tooltipIconColor={infoIconColor}
            containerStyle={getContainerStyle(card, index)}
            backgroundColor={primaryBgColor}
          >
            <Visible
              when={!card.isLoading}
              renderIf={
                <>
                  <div className="flex items-center">
                    {card.valueIcon}
                    <div
                      className={classNames(
                        'xs:whitespace-nowrap text-[40px] font-bold leading-none md:whitespace-normal lg:whitespace-normal xl:whitespace-normal',
                        secondaryTextColor && `text-${secondaryTextColor}`
                      )}
                    >
                      {card.isError ? data?.valuePlaceholder : card.value}
                    </div>
                  </div>
                  <div className={classNames('text-lg', `text-${tertiaryTextColor}`)}>{card.footer}</div>
                </>
              }
              renderElse={<Spinner />}
            />
          </InfoCard>
        );
      })}
    </div>
  );
}
