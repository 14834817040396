import { SbBlokData } from '@storyblok/react';
import classNames from 'classnames';
import { DynamicComponent } from './DynamicComponent';

interface GridProps {
  blok: {
    body: SbBlokData[];
    isRowAligned: boolean;
    columnSpanWidth: number;
  };
  data: Record<string, unknown>;
}

export const GridContainer = ({ blok, data }: GridProps) => {
  const { body, isRowAligned, columnSpanWidth } = blok;
  return (
    <div
      className={classNames(
        `col-span-12 lg:col-span-${columnSpanWidth ? Number(columnSpanWidth) : 6} flex`,
        isRowAligned ? 'flex-row gap-x-3' : 'flex-col gap-y-3'
      )}
    >
      {body
        ? body.map((nestedBlok, index) => (
            <DynamicComponent key={`grid-element-${index}`} blok={nestedBlok} data={data} />
          ))
        : null}
    </div>
  );
};
