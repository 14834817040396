import { Immobilie } from '@dtk/query';
import { EstatePictogram } from '@dtk/report';
import {
  CardSize,
  InfoCard,
  QueryStatusCard,
  RadialGauge,
  Theme,
  TooltipColor,
  getStoryblokTheme,
} from '@dtk/ui-components';
import classNames from 'classnames';

const getPercentageValue = (value: number) => {
  return `${Math.round(value * 100)}%`;
};

export function PropertyShareCard({ blok, data }: PropertyShareCardProps) {
  const percentageLabelStyle = '-mb-[10px] text-[40px] font-bold';
  const labelStyle = 'text-[16px] font-bold leading-[22.63px]';

  const { remainingOwnership, soldPart, title, tooltipText } = blok;
  const { estateLoading, estateError } = data;

  const finanzielleDaten = data?.estateData?.finanzielleDaten;
  const basisdaten = data?.estateData?.basisdaten;
  const bauweise = data?.estateData?.bauweise;

  const desiredPayment = finanzielleDaten?.wunschauszahlung;
  const basePrice = finanzielleDaten?.kaufpreisMittel
    ? finanzielleDaten?.kaufpreisMittel
    : finanzielleDaten?.geschaetzterImmobilienwert;

  const propertyValue = basePrice || 1; // to avoid division by zero
  const dtkShareValue = desiredPayment / propertyValue;

  const dtkShare = getPercentageValue(propertyValue === 1 ? 0 : dtkShareValue);
  const ownerShare = getPercentageValue(propertyValue === 1 ? 1 : 1 - dtkShareValue);

  const ownerSharePercent = (1 - dtkShareValue) * 100;

  const {
    primaryTextColor,
    primaryBgColor,
    secondaryTextColor,
    tertiaryTextColor,
    infoIconColor,
    infoIconTooltipColorVariant,
  } = getStoryblokTheme(blok?.theme);

  if (estateLoading) {
    return <QueryStatusCard title={title} tooltipText={tooltipText} isLoading={true} />;
  }

  if (estateError) {
    return (
      <QueryStatusCard title={title} tooltipText={tooltipText} state={'tryAgainLater'} cardSize={CardSize.SMALL} />
    );
  }

  if (!finanzielleDaten || !basisdaten || !bauweise) {
    return (
      <QueryStatusCard title={title} tooltipText={tooltipText} state={'noDataAvailable'} cardSize={CardSize.SMALL} />
    );
  }

  return (
    <div className="xs:col-span-12 col-span-12 rounded-lg shadow-lg md:col-span-6 lg:col-span-6 xl:col-span-3">
      <InfoCard
        title={title}
        tooltipText={tooltipText}
        titleColor={primaryTextColor}
        tooltipIconColor={infoIconColor}
        backgroundColor={primaryBgColor}
        tooltipColorVariant={infoIconTooltipColorVariant as TooltipColor}
        containerStyle="rounded-lg"
      >
        <div className="xs:pb-4 flex h-full flex-col items-center justify-center lg:pb-0">
          <RadialGauge
            percent={ownerSharePercent}
            isFullCircle
            contentStyle="flex justify-center items-center"
            svgPrimaryColor={secondaryTextColor}
            svgSecondaryColor={tertiaryTextColor}
          >
            <EstatePictogram
              classNames="w-[120px] h-[137px] flex items-center justify-center"
              immobilientyp={basisdaten?.immobilientyp}
              dachausbau={bauweise?.dachausbau}
              anzahlGeschosse={basisdaten?.anzahlGeschosse}
              kellerart={bauweise?.kellerart}
            />
          </RadialGauge>

          <div className="xs:-mt-[15px] flex w-full justify-evenly lg:-mt-[10px]">
            <div className="mr-[15px] w-[150px] text-left xl:w-auto">
              <div className={classNames(secondaryTextColor && `text-${secondaryTextColor}`, percentageLabelStyle)}>
                {ownerShare}
              </div>
              <div className={classNames(primaryTextColor && `text-${primaryTextColor}`, labelStyle)}>
                {remainingOwnership}
              </div>
            </div>

            <div className="w-[150px] text-right xl:w-auto">
              <div className={classNames(tertiaryTextColor && `text-${tertiaryTextColor}`, percentageLabelStyle)}>
                {dtkShare}
              </div>
              <div className={classNames(primaryTextColor && `text-${primaryTextColor}`, labelStyle)}>{soldPart}</div>
            </div>
          </div>
        </div>
      </InfoCard>
    </div>
  );
}

interface PropertyShareBlok {
  component: string;
  remainingOwnership: string;
  soldPart: string;
  title: string;
  tooltipText: string;
  _uid: string;
  theme: Theme;
}

interface PropertyShareCardProps {
  blok: PropertyShareBlok;
  data: {
    estateData: Immobilie;
    estateError: boolean;
    estateLoading: boolean;
  };
}
