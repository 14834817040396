import { StageStatus } from '@dtk/query';
import { StepStatus } from '@dtk/ui-components';

export interface TeilkaufStatusStep {
  id: number;
  key: StageStatus | 'Club';
  name: string;
  description: string;
  status: StepStatus;
}

export const steps: TeilkaufStatusStep[] = [
  { id: 1, key: StageStatus.Initial, name: 'Bewertung', description: 'Bewertung', status: StepStatus.complete },
  {
    id: 2,
    key: StageStatus.unverbindliches_Angebot,
    name: 'Unverbindliches Angebot"',
    description: 'Unverbindliches Angebot',
    status: StepStatus.current,
  },
  { id: 3, key: StageStatus.Gutachten, name: 'Gutachten', description: 'Gutachten', status: StepStatus.upcoming },
  {
    id: 4,
    key: StageStatus.verbindliches_Angebot,
    name: 'Verbindliches Angebot',
    description: 'Verbindliches Angebot',
    status: StepStatus.upcoming,
  },
  { id: 5, key: StageStatus.Kaufvertrag, name: 'Kaufvertrag', description: 'Kaufvertrag', status: StepStatus.upcoming },
  {
    id: 6,
    key: StageStatus.Angebot_angenommen,
    name: 'Auszahlung Ihres Wunschbetrags',
    description: 'Auszahlung Ihres Wunschbetrags',
    status: StepStatus.upcoming,
  },
];

export const clubSteps: TeilkaufStatusStep[] = [
  { id: 1, key: 'Club', name: 'Neu Registriert', description: 'Neu Registriert', status: StepStatus.complete },
  { id: 2, key: StageStatus.Initial, name: 'Bewertung', description: 'Bewertung', status: StepStatus.upcoming },
];
