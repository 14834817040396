import { StageStatus } from '@dtk/query';
import { Steps, StepStatus } from '@dtk/ui-components';
import { steps, clubSteps } from './content';

export const mapStageToSteps = (status: StageStatus | 'Club'): Steps[] => {
  let currentFound = false;
  const progressSteps = status === 'Club' ? clubSteps : steps;

  return progressSteps
    .map((step) => {
      if (step.key === status) {
        currentFound = true;
        return { ...step, status: StepStatus.current };
      }
      return currentFound ? { ...step, status: StepStatus.upcoming } : { ...step, status: StepStatus.complete };
    })
    .map((step) => ({
      id: step.id,
      name: step.key,
      key: step.key,
      description: step.description,
      status: step.status,
    }));
};

export const calculatePercent = (steps: Steps[]): number => {
  const currentStep = steps.find((step) => step.status === StepStatus.current);
  if (!currentStep) return 0;
  switch (currentStep.name) {
    case StageStatus.Initial:
      return 10;
    case StageStatus.unverbindliches_Angebot:
      return 20;
    case StageStatus.Gutachten:
      return 40;
    case StageStatus.verbindliches_Angebot:
      return 60;
    case StageStatus.Kaufvertrag:
      return 80;
    case StageStatus.Angebot_angenommen:
      return 100;
    case StageStatus.Angebot_nicht_angenommen:
      return 100;
    default:
      return 10;
  }
};
export const isLeadOrBewertungOrUnvberbindlichesAngebot = (status: StageStatus | undefined): boolean => {
  return status === StageStatus.Initial || status === StageStatus.unverbindliches_Angebot || status === undefined;
};
