import { StoryblokComponent } from '@storyblok/react';

export function GlobalReference({ blok }) {
  return blok.reference.content?.global
    ? blok.reference.content.global.map((nestedBlok) => (
        <div key={nestedBlok._uid} className="col-span-12 lg:col-span-6">
          <StoryblokComponent blok={nestedBlok} />
        </div>
      ))
    : null;
}
