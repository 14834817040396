import { Button } from '@dtk/ui-components';
import { ArrowRightIcon, CheckCircleIcon } from '@heroicons/react/solid';
import Link from 'next/link';

export interface SlideOverBodyProps {
  headline?: string;
  subline?: string;
  description: string;
  statusInfoPoints: string[];
  cta: { title: string; href: string };
  furtherInfoPoints?: string[];
}

export const SliderOverBody = (props: SlideOverBodyProps) => {
  return (
    <div className="inset-0 py-4 px-2 sm:px-6">
      <p className="text-navy text-base font-semibold">Was bedeutet mein Status und wie geht es weiter?</p>
      <p className="mt-4 font-normal">{props.description}</p>

      <ul className="mt-8 mb-3 w-full rounded-lg text-blue-800">
        {props.statusInfoPoints.map((point, index) => (
          <li key={`${point}-${index}`} className="mb-4">
            <p className="w-fill flex rounded-lg">
              <CheckCircleIcon className="text-navy-400 h-full w-4 flex-none pt-1" />
              <span className="ml-3 font-semibold" title={point}>
                {point}
              </span>
            </p>
          </li>
        ))}
      </ul>

      <Link href={props.cta.href}>
        <Button className="mt-6 mb-8" size="lg" fullWidth>
          {props.cta.title}
        </Button>
      </Link>

      {props.furtherInfoPoints && (
        <>
          <p className="text-navy text-lg font-semibold">Wie geht es danach weiter? </p>
          <ul className="mt-4 mb-3 w-full rounded-lg text-blue-800">
            {props.furtherInfoPoints.map((point, index) => (
              <li key={`${point}-${index}`} className="mb-4">
                <p className="w-fill flex rounded-lg">
                  <ArrowRightIcon className="text-navy-400 h-full w-4 flex-none pt-1" />
                  <span className="ml-3 font-semibold" title={point}>
                    {point}
                  </span>
                </p>
              </li>
            ))}
          </ul>
        </>
      )}
    </div>
  );
};
