import { DarkVerticalBanner, DarkVerticalBannerProps } from '@dtk/ui-components';

type TeaserBannerProps = DarkVerticalBannerProps;

export function TeaserBanner({ blok }) {
  const teaserBannerProps = blok as TeaserBannerProps;
  return (
    <div className="col-span-12 lg:col-span-6">
      <DarkVerticalBanner {...teaserBannerProps} />
    </div>
  );
}
