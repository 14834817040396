import { StoryblokComponent } from '@dtk/storyblok';

import {
  CardsContainer,
  ClubInfoCard,
  CustomerTasksCard,
  DashboardContactCard,
  DashboardDTKIndex,
  GlobalReference as global_reference,
  PropertyShareCard,
  MarketPriceTrendCard,
  TeaserBanner,
} from '../03_business';
import { ContactCardFallback } from './ContactCardFallback';
import { DoubleInfoCardWithValue } from './DoubleInfoCardWithValue';
import { InfoCardWithValueIcon } from './InfoCardWithValueIcon';
import { ProcessStatusCard } from './ProcessStatusCard';
import { GridContainer } from './GridContainer';

const components = {
  CardsContainer,
  InfoCardWithValueIcon,
  DoubleInfoCardWithValue,
  ProcessStatusCard,
  PropertyShareCard,
  TasksCard: CustomerTasksCard,
  DashboardDTKIndex,
  MarketPriceTrendCard,
  DashboardContactCard,
  ContactCardFallback,
  TeaserBanner,
  global_reference,
  ClubInfoCard,
  GridContainer,
};

export const DynamicComponent = ({ blok, data }: { blok: unknown; data?: unknown }) => {
  return <StoryblokComponent blok={blok} components={components} data={data} />;
};
