import { StageCurrentActivity, useGetCustomerdocumentsDocuments, useStageContext } from '@dtk/query';
import { Story } from '@dtk/storyblok';
import {
  CardSize,
  CustomerTask,
  CustomerTasks,
  InfoCard,
  QueryStatusCard,
  Theme,
  TooltipColor,
  getStoryblokTheme,
} from '@dtk/ui-components';
import { filterDocumentTasks, getActionTasksCustomerStage, getTasksFromStoryblok } from './utils';

export function CustomerTasksCard({ blok }: CustomerTasksCardProps) {
  const { title, tooltipText, emptyMessage, tasks: storyblokTasks } = blok;
  const allTasks = getTasksFromStoryblok(storyblokTasks);

  const { data: customerDocumentsResponse, isLoading, isError } = useGetCustomerdocumentsDocuments();
  const stageContext = useStageContext();

  if (!stageContext || !stageContext.status || isError) {
    return (
      <QueryStatusCard title={title} tooltipText={tooltipText} state={'noDataAvailable'} cardSize={CardSize.MEDIUM} />
    );
  }

  if (!customerDocumentsResponse || isLoading) {
    return <QueryStatusCard title={title} tooltipText={tooltipText} isLoading={true} cardSize={CardSize.MEDIUM} />;
  }

  const tasks = getActionTasksCustomerStage(stageContext.currentActivity, allTasks);

  let filteredTasks: CustomerTask[] | null = tasks;

  if (
    stageContext.currentActivity === StageCurrentActivity['Angebot_nachfassen_-_Rcksprache_gewnscht'] ||
    stageContext.currentActivity === StageCurrentActivity.Anfragen_der_Unterlagen__berprfen
  ) {
    const { data: documents } = customerDocumentsResponse;

    filteredTasks = filterDocumentTasks(tasks, documents);
  }

  const { primaryTextColor, primaryBgColor, infoIconColor, infoIconTooltipColorVariant } = getStoryblokTheme(
    blok?.theme
  );

  return (
    <div className="xs:col-span-12 col-span-12 rounded-lg shadow-lg lg:col-span-6">
      <InfoCard
        title={title}
        tooltipText={tooltipText}
        containerStyle="min-h-[368px] rounded-lg"
        titleColor={primaryTextColor}
        tooltipColorVariant={infoIconTooltipColorVariant as TooltipColor}
        tooltipIconColor={infoIconColor}
        backgroundColor={primaryBgColor}
      >
        {filteredTasks ? (
          <CustomerTasks
            tasks={filteredTasks}
            infoIconTooltipColorVariant={infoIconTooltipColorVariant as TooltipColor}
          />
        ) : (
          <div className="text-coolGray-400 flex h-full items-center justify-center">{emptyMessage}</div>
        )}
      </InfoCard>
    </div>
  );
}

interface CustomerTasksCardBlok {
  component: string;
  title: string;
  tooltipText: string;
  emptyMessage: string;
  _uid: string;
  tasks: Story<Tasks>;
  theme: Theme;
}

interface CustomerTasksCardProps {
  blok: CustomerTasksCardBlok;
}

interface Tasks {
  component: string;
  global: [];
  _uid: string;
}
