import 'leaflet/dist/leaflet.css';

import React, { useEffect, useState } from 'react';

export interface PropertyMapAreaProps {
  zoom?: number;
  tileTheme?: string;
  iconSize?: number;
  address: string;
  style?: React.CSSProperties;
}

export function PropertyMapArea({
  zoom = 30,
  tileTheme = 'https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png',
  iconSize = 30,
  address,
  style,
}: PropertyMapAreaProps) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [components, setComponents] = useState<any>(null);
  const [position, setPosition] = useState<number[]>([]);

  useEffect(() => {
    const fetchLibrary = async () => {
      // Library cannot be imported with SSR, window object needs to exist
      const { MapContainer, Marker, TileLayer } = await import('react-leaflet');
      const { OpenStreetMapProvider } = await import('leaflet-geosearch');

      const L = await import('leaflet');
      setComponents({
        MapContainer,
        Marker,
        TileLayer,
        isLoaded: true,
        L,
        OpenStreetMapProvider,
      });
    };

    fetchLibrary();
  }, []);

  useEffect(() => {
    const getCustomerCoordinates = async () => {
      const provider = new components.OpenStreetMapProvider();
      const results = await provider.search({ query: address });

      setPosition([results[0]?.y, results[0]?.x]);
    };

    if (address && components?.OpenStreetMapProvider && components) {
      getCustomerCoordinates();
    }
  }, [address, components?.OpenStreetMapProvider, components]);

  if (!components?.isLoaded || !position.length) {
    return null;
  }

  return (
    <div className="h-full w-full rounded-b-lg shadow-lg">
      <components.MapContainer
        style={{ height: '100%', width: '100%', zIndex: 1, ...style }}
        center={position}
        zoom={zoom}
        scrollWheelZoom
      >
        <components.TileLayer url={tileTheme} />
        <components.Marker
          icon={
            new components.L.icon({
              iconUrl:
                'https://a.storyblok.com/f/148087/x/395e2a6334/location_placeholder_map_marker_icon_177180-4.ico',
              iconSize,
            })
          }
          position={position}
        ></components.Marker>
      </components.MapContainer>
    </div>
  );
}
