import { TeilverkaufFAQCard } from '@dtk/estate-overview';
import { StageStatus, useGetProposalEstateValueIncrease, useStageContext } from '@dtk/query';
import { Container, GreetingsHeroExternalDataProps, DefaultModal, Spinner } from '@dtk/ui-components';
import { useGetEstateData, useGetEstatePricingTrend } from '@dtk/query';
import { useStory } from '@dtk/storyblok';
import { DashboardHeroSectionWrapper, DynamicComponent, CustomerInitialStageContent } from './02_composite';

const DEFAULT_PLACEHOLDER = '-';

interface StoryblokDashboard {
  heroSection: GreetingsHeroExternalDataProps[];
  dashboardContainer: [
    {
      cards: [];
      component: string;
      _uid: string;
    }
  ];
  valuePlaceholder?: string;
}

export function Dashboard() {
  const { data: estate, isLoading: estateLoading, isError: estateError } = useGetEstateData();
  const { data: trend, isLoading: trendLoading, isError: trendError } = useGetEstatePricingTrend();
  const { data: dtkIndex, isLoading: isIndexLoading, isError: dtkIndexError } = useGetProposalEstateValueIncrease();

  const stageContext = useStageContext();

  const status = stageContext.status;
  const interestedInPartialSale = stageContext.interestedInPartialSale;

  const estateData = interestedInPartialSale
    ? estate?.data
    : {
        ...estate?.data,
        finanzielleDaten: {
          geschaetzterImmobilienwert: '300000',
          wunschauszahlung: '100000',
        },
        basisdaten: { wohnflaeche: '63' },
      };
  const trendData = trend?.data;
  const dtkData = dtkIndex?.data;

  const isLead = status === StageStatus.Initial;
  const slug = isLead ? 'lead' : 'opportunity';

  const { story, loading: storyLoading } = useStory<StoryblokDashboard>({ content: 'dashboard', slug });

  const dashboardContainer = story?.content?.dashboardContainer;
  const heroSection = story?.content?.heroSection;
  const valuePlaceholder = story?.content?.valuePlaceholder || DEFAULT_PLACEHOLDER;

  if (storyLoading || !dashboardContainer) {
    return <Spinner />;
  }

  return (
    <div>
      <DashboardHeroSectionWrapper externalData={heroSection[0]} />

      <Container className="mt-8 space-y-8" backgroundColor="coolGray-100">
        <DefaultModal showDialog={isLead && !interestedInPartialSale}>
          <CustomerInitialStageContent />
        </DefaultModal>
        {dashboardContainer?.map((blok) => (
          <DynamicComponent
            key={blok._uid}
            blok={blok}
            data={{
              estateData,
              trendData,
              isLead,
              dtkData,
              stageContext,
              estateError,
              trendError,
              dtkIndexError,
              valuePlaceholder,
              estateLoading,
              trendLoading,
              isIndexLoading,
            }}
          />
        ))}

        <div className="pb-[2rem]">
          <TeilverkaufFAQCard />
        </div>
      </Container>
    </div>
  );
}
