/**
 * Variants:
 * - Doppelhaus
 *   - Doppelhaus, Flachdach, 2.Stock, unterkellert
 *   - Doppelhaus, Flachdach, unterkellert
 *   - Doppelhaus, 2.Stock, unterkellert
 *   - Doppelhaus, Flachdach, 2.Stock
 *   - Doppelhaus, Flachdach
 *   - Doppelhaus, unterkellert
 *   - Doppelhaus, 2.Stock
 *   - Doppelhaus
 * - ETW (Eigentumswohnung)
 *   -- Eigentumswohnung, Pent
 *   -- Eigentumswohnung
 * - Haus
 *   - Haus, Flachdach, 2.Stock, unterkellert
 *   - Haus, Flachdach, unterkellert
 *   - Haus, 2.Stock, unterkellert
 *   - Haus, Flachdach, 2.Stock
 *   - Haus, Flachdach
 *   - Haus, unterkellert
 *   - Haus, 2.Stock
 *   - Haus
 * - Mehrfamilienhaus
 */
const urls: Record<string, string> = {
  'haus-flachdach-2stock-unterkellert':
    'https://a.storyblok.com/f/148087/361x348/8ac99f3aa8/haus-flachdach-2stock-unterkellert.png',
  'haus-flachdach': 'https://a.storyblok.com/f/148087/361x175/be7f55adac/haus-flachdach.png',
  haus: 'https://a.storyblok.com/f/148087/361x213/1d9dfbefbc/haus.png',
  'haus-flachdach-2stock': 'https://a.storyblok.com/f/148087/361x258/825ae50df2/haus-flachdach-2stock.png',
  'haus-2stock-unterkellert': 'https://a.storyblok.com/f/148087/361x384/6c2c78d87f/haus-2stock-unterkellert.png',
  'haus-unterkellert': 'https://a.storyblok.com/f/148087/361x300/4b766e7c70/haus-unterkellert.png',
  'haus-flachdach-unterkellert': 'https://a.storyblok.com/f/148087/361x264/4331f89ae3/haus-flachdach-unterkellert.png',
  mehrfamilienhaus: 'https://a.storyblok.com/f/148087/181x126/1e88182f6e/mehrfamilienhaus.png',
  'haus-2stock': 'https://a.storyblok.com/f/148087/361x295/974ddae982/haus-2stock.png',
  'doppelhaus-2stock-unterkellert':
    'https://a.storyblok.com/f/148087/483x475/a2efe5107b/doppelhaus-2stock-unterkellert.png',
  'doppelhaus-flachdach-2stock': 'https://a.storyblok.com/f/148087/484x392/ded75dbef2/doppelhaus-flachdach-2stock.png',
  'doppelhaus-unterkellert': 'https://a.storyblok.com/f/148087/483x475/296083acbc/doppelhaus-unterkellert.png',
  'doppelhaus-flachdach-unterkellert':
    'https://a.storyblok.com/f/148087/484x405/b627c8cd94/doppelhaus-flachdach-unterkellert.png',
  'doppelhaus-flachdach': 'https://a.storyblok.com/f/148087/484x298/605bff880b/doppelhaus-flachdach.png',
  'doppelhaus-flachdach-2stock-unterkellert':
    'https://a.storyblok.com/f/148087/483x476/d45c2a9d8e/doppelhaus-flachdach-2stock-unterkellert.png',
  eigentumswohnung: 'https://a.storyblok.com/f/148087/181x127/f500e151bb/eigentumswohnung.png',
  'doppelhaus-2stock': 'https://a.storyblok.com/f/148087/484x393/877be72a14/doppelhaus-2stock.png',
  doppelhaus: 'https://a.storyblok.com/f/148087/484x337/e8aab10636/doppelhaus.png',
} as const;

export const EstatePictogram = ({
  immobilientyp,
  dachausbau,
  anzahlGeschosse,
  kellerart,
  classNames,
  size = 'm',
}: EstatePictogramProps) => {
  return (
    <div className={classNames}>
      <img
        width={size === 's' ? 135 : 160}
        src={urls[getEstatePictogramName(immobilientyp, dachausbau, anzahlGeschosse, kellerart)]}
        alt="Haus"
      />
    </div>
  );
};

interface EstatePictogramProps {
  immobilientyp: string; // Einfamilienhaus, Einfamilienhaus mit Einliegerwohnung, Zweifamilienhaus, Eigentumswohnung, 'Mehrfamilienhaus', 'unbebautes Grundstück'
  dachausbau?: string; // 'Flachdach'
  anzahlGeschosse?: number; // 1, 2, ...
  kellerart?: string; // Vollunterkellert, 'Teilweise unterkellert', 'Nicht unterkellert'
  classNames?: string;
  size?: 's' | 'm';
}

export const getEstatePictogramName = (
  immobilientyp: string,
  dachausbau?: string,
  anzahlGeschosse?: number,
  kellerart?: string
) => {
  let pictogramName = '';
  switch (immobilientyp) {
    case 'Einfamilienhaus':
    case 'Einfamilienhaus mit Einliegerwohnung':
      pictogramName += 'haus';
      break;

    case 'Zweifamilienhaus':
      pictogramName += 'doppelhaus';
      break;

    case 'Mehrfamilienhaus':
      return 'mehrfamilienhaus';

    case 'Eigentumswohnung':
      return 'eigentumswohnung';

    default:
      pictogramName += 'haus';
      break;
  }

  if (dachausbau === 'Flachdach') {
    pictogramName += '-flachdach';
  }

  if (anzahlGeschosse && anzahlGeschosse > 1) {
    pictogramName += '-2stock';
  }

  if (kellerart === 'Vollunterkellert' || kellerart === 'Teilweise unterkellert') {
    pictogramName += '-unterkellert';
  }
  return pictogramName;
};
