import { DocumentCategory, DocumentList, StageCurrentActivity } from '@dtk/query';
import { CustomerTask, CustomerTaskUniqueNames } from '@dtk/ui-components';

export const getActionTasksCustomerStage = (
  activity: StageCurrentActivity | undefined,
  tasks: CustomerTask[]
): CustomerTask[] | null => {
  const activityTasks = tasks?.filter((task) => task.activities?.includes(activity)) || [];

  if (!activityTasks || activityTasks.length === 0) {
    return null;
  }

  activityTasks.sort((a, b) => (Number(a.priority) > Number(b.priority) ? 1 : -1));

  return activityTasks;
};

export const foundMissingUserDocs = (documents: DocumentList) => {
  const categories = Object.values(DocumentCategory);
  const userDocsCategories = categories.filter(
    (category) =>
      category !== DocumentCategory.teilkaufreport &&
      category !== DocumentCategory.vollmacht &&
      category !== DocumentCategory.sonstige
  );

  const foundMissingDoc = userDocsCategories.some((category) => {
    const exist = documents.some((document) => document.category === category);

    return !exist;
  });

  return foundMissingDoc;
};

export const filterDocumentTasks = (tasks: CustomerTask[] | null, documents: DocumentList): CustomerTask[] | null => {
  if (!tasks) {
    return null;
  }

  let filteredTasks = tasks;

  if (foundMissingUserDocs(documents)) {
    const vollmachtAdded = documents.some((document) => document.category === DocumentCategory.vollmacht);

    if (vollmachtAdded) {
      filteredTasks = tasks.filter((task) => task.uniqueName !== CustomerTaskUniqueNames.VOLLMACHT);
    }
  } else {
    filteredTasks = tasks.filter(
      (task) =>
        task.uniqueName !== CustomerTaskUniqueNames.VOLLMACHT &&
        task.uniqueName !== CustomerTaskUniqueNames.MISSING_DOCUMENTS
    );
  }

  return filteredTasks && filteredTasks.length > 0 ? filteredTasks : null;
};

export const getTasksFromStoryblok = (tasks) => {
  if (!tasks) {
    return;
  }

  return tasks?.content?.global;
};
