import { StageCurrentActivity } from '@dtk/query';
import { SlideOver } from '@dtk/ui-components';
import { contentCurrentActivity, fallBack } from './content';
import { SlideOverBodyProps, SliderOverBody } from './SliderOverCurrentActivityBody';

export const SlideOverCurrentActivity = ({ isOpen, setOpen, sliderBodyProps }: SlideOverCurrentActivityProps) => {
  return (
    <SlideOver isOpen={isOpen} setOpen={setOpen} headline={sliderBodyProps.headline} subline={sliderBodyProps.subline}>
      <SliderOverBody {...sliderBodyProps} />
    </SlideOver>
  );
};

export const getSliderOverBodyContent = (currentActivity: StageCurrentActivity | undefined): SlideOverBodyProps => {
  let props;
  if (currentActivity) {
    props = contentCurrentActivity.find((sliderContent) =>
      sliderContent.currentActivities.includes(currentActivity)
    )?.sliderBodyContent;
  }
  return props ? props : fallBack;
};

interface SlideOverCurrentActivityProps {
  isOpen: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
  sliderBodyProps: SlideOverBodyProps;
}
