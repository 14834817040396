import { ErrorState, Info, TooltipColor } from '@dtk/ui-components';

import { SocioDemographics } from '@dtk/query';
import classNames from 'classnames';
import { getSocioDemographicStats } from '@dtk/sociodemographics';

const numberMap = new Map<number, number>([
  [6, 0],
  [5, 1],
  [4, 2],
  [3, 3],
  [2, 4],
  [1, 5],
]);

export function Scoring({
  socioData,
  primaryTextColor,
  secondaryTextColor,
  tooltips,
  infoIconTooltipColorVariant = 'gray',
}: {
  socioData: SocioDemographics;
  primaryTextColor?: string;
  secondaryTextColor?: string;
  tooltips?: {
    field: string;
    text: string;
  }[];
  infoIconTooltipColorVariant?: string;
}) {
  if (!socioData) {
    return (
      <div className="flex h-full items-center justify-center">
        <ErrorState state={'noDataAvailable'} className={'last:[&_path]:fill-[#fff]'} />
      </div>
    );
  }

  return (
    <div>
      <div className="flex flex-col xl:grid xl:grid-cols-2 xl:gap-x-2">
        {getSocioDemographicStats(socioData, numberMap, secondaryTextColor)
          ?.filter((stat) => stat.isDataAvailable)
          .map((stat) => (
            <div className="flex h-20 flex-row items-center rounded-lg p-2" key={stat.title}>
              <div className={classNames(primaryTextColor && `text-${primaryTextColor} [&_path]:fill-current`)}>
                {stat.icon}
              </div>
              <div className="ml-2 flex flex-col">
                <div className={classNames('flex items-center', primaryTextColor && `text-${primaryTextColor}`)}>
                  <div className="h-full text-lg">{stat.title}</div>
                  <Info
                    content={tooltips?.find((tooltip) => tooltip.field === stat.title)?.text}
                    variant="tooltip"
                    colorVariant={infoIconTooltipColorVariant as TooltipColor}
                    buttonStyle={{ display: 'flex', marginLeft: '6px' }}
                  />
                </div>
                <div className="flex flex-row">{stat.stars}</div>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}
