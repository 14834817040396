import { StageCurrentActivity, StageStatus } from '@dtk/query';
import { SlideOverBodyProps } from './SliderOverCurrentActivityBody';

export const fallBack: SlideOverBodyProps = {
  headline: 'Bewertung',
  description:
    'Ihre Angebotsanfrage für ein unverbindliches und kostenloses Angebot ist bei uns eingegangen. Unsere Immobilienexperten bereiten alle weiteren Schritte zur Bewertung Ihrer Immobilie vor. ',
  statusInfoPoints: [
    'Wir prüfen Ihre Angebotsanfrage',
    'Unsere Immobilienexperten bewerten Ihre Immobilie',
    'Kontaktieren Sie uns gerne bei Rückfragen',
  ],
  cta: { title: 'Kontakt mit Berater aufnehmen', href: '/kontakt' },
  furtherInfoPoints: [
    'Sie erhalten Ihr unverbindliches Teilverkauf-Angebot',
    'Wir prüfen Ihre eingereichten Objektunterlagen',
    'Ein Gutachtertermin wird mit Ihnen vereinbart',
  ],
};

interface SliderOverCurrentActivityContent {
  currentActivities: StageCurrentActivity[];
  sliderBodyContent: SlideOverBodyProps;
}

export const contentCurrentActivity: SliderOverCurrentActivityContent[] = [
  {
    currentActivities: [
      StageCurrentActivity.Erstellung_des_Angebots__Versand,
      StageCurrentActivity.Angebot_nachfassen,
      StageCurrentActivity.Anfragen_der_Unterlagen__berprfen,
    ],
    sliderBodyContent: {
      headline: 'Unverbindliches Angebot',
      subline: 'Unverbindliches Angebot liegt vor',
      description:
        'Ihnen liegt ein unverbindliches und kostenfreies Angebot für Ihren Immobilien-Teilverkauf vor. Schauen Sie sich die Unterlagen in Ruhe an. Wenn Ihnen das Angebot zusagt, werden wir uns um die weiteren Schritte kümmern.',
      statusInfoPoints: [
        'Sie haben Ihr unverbindliches Angebot erhalten',
        'Prüfen Sie Ihr Teilverkauf-Angebot in Ruhe',
        'Ihr Teilkaufberater steht Ihnen bei Fragen zur Seite',
      ],
      cta: { title: 'Zu meinen Objektunterlagen', href: '/dokumente' },
      furtherInfoPoints: [
        'Sie reichen Ihre Objektunterlagen ein',
        'Wir prüfen Ihre Dokumente',
        'Ein Gutachtertermin wird mit Ihnen vereinbart',
      ],
    },
  },
  {
    currentActivities: [
      StageCurrentActivity.Grundbuchauszug_prfen,
      StageCurrentActivity['Grundbuchauszug_prfen_-_Korrektur'],
      StageCurrentActivity['Grundbuchauszug_prfen_-_2_Prfung'],
    ],
    sliderBodyContent: {
      headline: 'Unverbindliches Angebot',
      subline: 'Prüfung Objektunterlagen',
      description:
        'Ihre Objektunterlagen für Ihren Teilverkauf sind bei uns eingegangen und werden nun sorgfältig geprüft. Die Bearbeitung kann eine gewisse Zeit in Anspruch nehmen. Bei Fragen steht Ihnen Ihr Teilkaufberater gerne zur Seite.',
      statusInfoPoints: [
        'Ihre Objektunterlagen sind bei uns eingegangen',
        'Wir prüfen Ihre eingereichten Unterlagen',
        'Sie erhalten zeitnah eine Rückmeldung von uns',
      ],
      cta: { title: 'Zu meinen Objektunterlagen', href: '/dokumente' },
      furtherInfoPoints: [
        'Ein Gutachtertermin wird mit Ihnen vereinbart',
        'Das Gutachten für Ihre Immobile wird erstellt',
        'Ihr Teilkaufberater steht Ihnen bei Fragen zur Seite',
      ],
    },
  },
  {
    currentActivities: [StageCurrentActivity.Gutachter_beauftragen, StageCurrentActivity.Gutachtentermin_eintragen],
    sliderBodyContent: {
      headline: 'Gutachten',
      subline: 'Beauftragung Gutachten',
      description:
        'Wir haben Ihre Objektunterlagen geprüft. Für die Bewertung Ihrer Immobilie beauftragen wir einen unabhängigen und zertifizierten Gutachter. Wenn Sie selbst einen Gutachter beauftragen möchten, kontaktieren Sie bitte Ihren Teilkaufberater.',
      statusInfoPoints: [
        'Wir haben Ihre Objektunterlagen geprüft',
        'Ein Gutachter meldet sich bezüglich eines Termins',
        'Sie können auch selbst einen Gutachter beauftragen',
      ],
      cta: { title: 'Kontakt mit Berater aufnehmen', href: '/kontakt' },
      furtherInfoPoints: [
        'Ein Gutachter kommt zur Objektbegutachtung vorbei',
        'Das Gutachten für Ihre Immobilie wird erstellt',
        'Ihr Teilkaufberater steht Ihnen bei Fragen zur Seite',
      ],
    },
  },
  {
    currentActivities: [
      StageCurrentActivity.Gutachten_wird_erstellt,
      StageCurrentActivity.Werte_eintragen,
      StageCurrentActivity.Gutachten_prfen,
      StageCurrentActivity['Gutachten_prfen_-_Korrektur'],
      StageCurrentActivity['Gutachten_prfen_-_final'],
    ],
    sliderBodyContent: {
      headline: 'Gutachten',
      subline: 'Gutachtertermin',
      description:
        'Ein unabhängiger und zertifizierter Gutachter vereinbart einen Termin mit Ihnen und kommt zur Objektbegutachtung vorbei. Anschließend meldet sich Ihr Teilkaufberater, um die weiteren Schritte für Ihren Immobilien-Teilverkauf zu besprechen.',
      statusInfoPoints: [
        'Ein Gutachter vereinbart einen Termin mit Ihnen',
        'Der Gutachter kommt zur Objektbegutachtung',
        'Ihr Teilkaufberater steht Ihnen bei Fragen zur Seite',
      ],
      cta: { title: 'Kontakt mit Berater aufnehmen', href: '/kontakt' },
      furtherInfoPoints: [
        'Das Gutachten für Ihre Immobilie wird erstellt',
        'Die Bearbeitungsdauer beträgt wenige Wochen',
        'Ihr Teilkaufberater meldet sich zeitnah bei Ihnen',
      ],
    },
  },
  {
    currentActivities: [StageCurrentActivity.Gutachtentermin_nachfassen],
    sliderBodyContent: {
      headline: 'Gutachten',
      subline: 'Gutachtenerstellung',
      description:
        'Ein unabhängiger und zertifizierter Gutachter hat Ihre Immobilie begutachtet und erstellt nun das Gutachten. In der Regel dauert die Erstellung des Gutachtens wenige Wochen. Sobald uns das Dokument vorliegt, werden wir uns bei Ihnen melden.',
      statusInfoPoints: [
        'Das Gutachten für Ihre Immobilie wird erstellt',
        'Die Bearbeitungsdauer beträgt wenige Wochen',
        'Bei Fragen steht Ihnen Ihr Teilkaufberater zur Seite',
      ],
      cta: { title: 'Kontakt mit Berater aufnehmen', href: '/kontakt' },
      furtherInfoPoints: [
        'Wenn das Gutachten vorliegt, melden wir uns bei Ihnen',
        'Sie erhalten ein verbindliches Angebot von uns',
        'Es werden die weiteren Schritte besprochen',
      ],
    },
  },
  {
    currentActivities: [
      StageCurrentActivity.Gutachten_besprochen__Angebot_verschickt,
      StageCurrentActivity.Rcksprache_mit_Eigentmer_verb_Angebot,
      StageCurrentActivity.Rcksprache_mit_Eigentmer_4_Wochen,
    ],
    sliderBodyContent: {
      headline: 'Gutachten',
      subline: 'Gutachten liegt vor',
      description:
        'Das Gutachten und der ermittelte Marktwert für Ihre Immobilie liegen uns vor. Ihr Teilkaufberater wird sich zeitnah bei Ihnen melden, um das verbindliche Angebot und die weiteren Schritte für Ihren Immobilien-Teilverkauf zu besprechen.',
      statusInfoPoints: [
        'Das Gutachten für Ihre Immobilie ist erstellt',
        'Sie erhalten zeitnah ein verbindliches Angebot',
        'Es werden die nächsten Schritte besprochen',
      ],
      cta: { title: 'Kontakt mit Berater aufnehmen', href: '/kontakt' },
      furtherInfoPoints: [
        'Nach Erhalt prüfen Sie Ihr verbindliches Angebot',
        'Sie haben dafür einige Wochen Bedenkzeit',
        'Bei Rückfragen kontaktieren Sie Ihren Teilkaufberater',
      ],
    },
  },
  {
    currentActivities: [
      StageCurrentActivity.Interne_Prfung,
      StageCurrentActivity['Interne_Prfung_-_Korrektur'],
      StageCurrentActivity['Interne_Prfung_-_final'],
    ],
    sliderBodyContent: {
      headline: 'Verbindliches Angebot',
      description:
        'Wir freuen uns, dass wir für Sie ein verbindliches Angebot erstellen können. Schauen Sie sich Ihr Angebot für den Immobilien-Teilverkauf in Ruhe an. Sie haben vier Wochen Bedenkzeit, um eine wohlüberlegte Entscheidung treffen zu können.',
      statusInfoPoints: [
        'Ihnen liegt ein verbindliches Teilverkauf-Angebot vor',
        'Prüfen Sie Ihr verbindliches Angebot sorgfältig',
        'Ihr Teilkaufberater wird sich bei Ihnen melden',
      ],
      cta: { title: 'Zu meinen Objektunterlagen', href: '/dokumente' },
      furtherInfoPoints: [
        'Der Kaufvertragsentwurf wird erstellt',
        'Sie können Fragen mit Ihrem Teilkaufberater besprechen',
        'Ein Termin zur Vertragsunterzeichnung wird vereinbart',
      ],
    },
  },
  {
    currentActivities: [StageCurrentActivity.Kaufvertrag_beauftragen__Terminvorschlge],
    sliderBodyContent: {
      headline: 'Kaufvertrag',
      subline: 'Beauftragung Kaufvertrag',
      description:
        'Der Kaufvertragsentwurf für den Teilverkauf wird erstellt und vom Notariat an Sie verschickt. Nach Erhalt meldet sich Ihr Teilkaufberater, um mit Ihnen offene Frage zu klären und einen Termin zur Vertragsunterzeichnung zu vereinbaren.',
      statusInfoPoints: [
        'Ihr persönlicher Kaufvertrag ist in Bearbeitung',
        'Das Notariat schickt Ihnen den Vertrag postalisch zu',
        'Ein Termin zur Vertragsunterzeichnung wird vereinbart',
      ],
      cta: { title: 'Kontakt mit Berater aufnehmen', href: '/kontakt' },
      furtherInfoPoints: [
        'Nach Erhalt können Sie den Kaufvertrag in Ruhe prüfen',
        'Ihr Teilkaufberater bespricht mit Ihnen den Vertrag',
        'Bei Fragen steht Ihnen Ihr Teilkaufberater zur Seite',
      ],
    },
  },
  {
    currentActivities: [StageCurrentActivity.Warten_bis_Kaufvertrag_verschickt],
    sliderBodyContent: {
      headline: 'Kaufvertrag',
      subline: 'Kaufvertragsentwurf ist verschickt',
      description:
        'Ihr Kaufvertragsentwurf wurde an Sie verschickt. Sie haben vier Wochen Zeit, um den Vertrag zu prüfen. Ihr persönlicher Teilkaufberater wird zur Besprechung des Kaufvertragsentwurfs und Beantwortung von Fragen auf Sie zukommen.',
      statusInfoPoints: [
        'Ihr Kaufvertragsentwurf ist auf dem Postweg zu Ihnen',
        'Sie haben vier Wochen Zeit, um den Vertrag zu prüfen',
        'Ihr Teilkaufberater wird sich bei Ihnen melden',
      ],
      cta: { title: 'Kontakt mit Berater aufnehmen', href: '/kontakt' },
      furtherInfoPoints: [
        'Ihr Teilkaufberater bespricht den Vertrag mit Ihnen',
        'Sie können im Gespräch offene Fragen klären',
        'Der Kaufvertrag wird unterzeichnet',
      ],
    },
  },
  {
    currentActivities: [
      StageCurrentActivity.ausfhrliche_Kaufvertragsbesprechung__Absprache_Notartermin,
      StageCurrentActivity.Rcksprache_mit_Eigentmer_Kaufvertrag,
      StageCurrentActivity.Rcksprache_mit_Eigentmer_2_Wochen,
    ],
    sliderBodyContent: {
      headline: 'Kaufvertrag',
      subline: 'Kaufvertragsbesprechung',
      description:
        'Ihr Teilkaufberater wird den Kaufvertragsentwurf mit Ihnen besprechen. In diesem Gespräch können Sie auch Fragen klären, die bei der Prüfung Ihres Vertrags aufgekommen sind.',
      statusInfoPoints: [
        'Ihr Kaufvertragsgespräch steht an',
        'Ihr Teilkaufberater beantwortet Ihnen Ihre Fragen',
        'Anschließend unterzeichnen Sie Ihren Vertrag',
      ],
      cta: { title: 'Kontakt mit Berater aufnehmen', href: '/kontakt' },
      furtherInfoPoints: [
        'Bei einem Notar erfolgt der Beurkundungstermin',
        'Sie erhalten Ihren Wunschbetrag auf Ihr Konto',
        'Bei Fragen steht Ihnen Ihr Teilkaufberater zur Seite',
      ],
    },
  },
  {
    currentActivities: [StageCurrentActivity.Notartermin_vereinbaren],
    sliderBodyContent: {
      headline: 'Kaufvertrag',
      subline: 'Notartermin',
      description:
        'Der Notartermin für Ihren Immobilien-Teilverkauf steht bald an. Bitte bedenken Sie, dass Sie für den Beurkundungstermin Ihren Personalausweis und die Objektunterlagen für Ihre Immobilie benötigen.',
      statusInfoPoints: [
        'Der Notartermin für den Teilverkauf steht kurz bevor',
        'Bringen Sie alle wichtigen Unterlagen mit zum Termin',
        'Ihr Teilkaufberater steht Ihnen bei Fragen zur Seite',
      ],
      cta: { title: 'Kontakt mit Berater aufnehmen', href: '/kontakt' },
      furtherInfoPoints: [
        'Sie erhalten die Auszahlungssumme auf Ihr Konto',
        'Danach ist Ihr Immobilien-Teilverkauf abgeschlossen',
        'Sie können Ihre Daten im Portal weiterhin einsehen',
      ],
    },
  },
  {
    currentActivities: [StageCurrentActivity.Bezahlt],
    sliderBodyContent: {
      headline: 'Auszahlung Ihres Wunschbetrags',
    description:
      'Der Teilverkauf Ihrer Immobilie ist nun abgeschlossen. Der Auszahlungsbetrag für Ihren Immobilien-Teilverkauf wird auf Ihr angegebenes Konto überwiesen und steht Ihnen zeitnah zur Verfügung.',
    statusInfoPoints: [
      'Der Auszahlungsbetrag wird auf Ihr Konto überwiesen',
      'Ihr Immobilien-Teilverkauf ist abgeschlossen',
      'Ihr Teilkaufberater steht Ihnen weiterhin zur Seite',
    ],
    cta: { title: 'Zu Ihren Immobilienbereich', href: '/meineimmobilie' },
    },
  },
];

export const contentStatus: Record<string, SlideOverBodyProps> = {
  [StageStatus.Initial]: fallBack,
  [StageStatus.unverbindliches_Angebot]: {
    headline: 'Unverbindliches Angebot',
    description:
      'Ihnen liegt ein unverbindliches und kostenfreies Angebot für Ihren Immobilien-Teilverkauf vor. Im Anschluss werden Ihre eingereichten Objektunterlagen für Ihren Teilverkauf sorgfältig geprüft. Die Bearbeitung kann eine gewisse Zeit in Anspruch nehmen. Bei Fragen steht Ihnen Ihr Teilkaufberater gerne zur Seite.',
    statusInfoPoints: [
      'Sie haben Ihr unverbindliches Angebot erhalten',
      'Sie reichen Ihre Objektunterlagen ein',
      'Wir prüfen Ihre eingereichten Unterlagen',
    ],
    cta: { title: 'Kontakt mit Berater aufnehmen', href: '/kontakt' },
    furtherInfoPoints: [
      'Ein Gutachtertermin wird mit Ihnen vereinbart',
      'Das Gutachten für Ihre Immobile wird erstellt',
      'Ihr Teilkaufberater steht Ihnen bei Fragen zur Seite',
    ],
  },
  [StageStatus.Gutachten]: {
    headline: 'Gutachten',
    description:
      'Wir haben Ihre Objektunterlagen geprüft. Für die Bewertung Ihrer Immobilie beauftragen wir einen unabhängigen und zertifizierten Gutachter für die Erstellung eines Gutachtens Ihrer Immobilie.',
    statusInfoPoints: [
      'Der Gutachter kommt zur Objektbegutachtung',
      'Das Gutachten für Ihre Immobilie wird erstellt',
      'Sie erhalten zeitnah ein verbindliches Angebot',
    ],
    cta: { title: 'Kontakt mit Berater aufnehmen', href: '/kontakt' },
    furtherInfoPoints: [
      'Nach Erhalt prüfen Sie Ihr verbindliches Angebot',
      'Sie haben dafür einige Wochen Bedenkzeit',
      'Bei Rückfragen kontaktieren Sie Ihren Teilkaufberater',
    ],
  },
  [StageStatus.Kaufvertrag]: {
    headline: 'Kaufvertrag',
    description:
      'Ihr Kaufvertragsentwurf wird an Sie verschickt. Ihr Teilkaufberater wird den Kaufvertragsentwurf mit Ihnen besprechen und einen Notartermin für den Teilverkauf mit Ihnen vereinbaren.',
    statusInfoPoints: [
      'Ihr Kaufvertragsentwurf ist auf dem Postweg zu Ihnen',
      'Ihr Kaufvertragsgespräch mit Ihrem Teilkaufberater steht an',
      'Der Notartermin für den Teilverkauf steht kurz bevor',
    ],
    cta: { title: 'Kontakt mit Berater aufnehmen', href: '/kontakt' },
    furtherInfoPoints: [
      'Sie erhalten die Auszahlungssumme auf Ihr Konto',
      'Danach ist Ihr Immobilien-Teilverkauf abgeschlossen',
      'Sie können Ihre Daten im Portal weiterhin einsehen',
    ],
  },
  [StageStatus.verbindliches_Angebot]: {
    headline: 'Verbindliches Angebot',
    description:
      'Wir freuen uns, dass wir für Sie ein verbindliches Angebot erstellen können. Schauen Sie sich Ihr Angebot für den Immobilien-Teilverkauf in Ruhe an. Sie haben vier Wochen Bedenkzeit, um eine wohlüberlegte Entscheidung treffen zu können.',
    statusInfoPoints: [
      'Ihnen liegt ein verbindliches Teilverkauf-Angebot vor',
      'Prüfen Sie Ihr verbindliches Angebot sorgfältig',
      'Ihr Teilkaufberater wird sich bei Ihnen melden',
    ],
    cta: { title: 'Zu meinen Objektunterlagen', href: '/dokumente' },
    furtherInfoPoints: [
      'Der Kaufvertragsentwurf wird erstellt',
      'Sie können Fragen mit Ihrem Teilkaufberater besprechen',
      'Ein Termin zur Vertragsunterzeichnung wird vereinbart',
    ],
  },
  [StageStatus.Angebot_angenommen]: {
    headline: 'Auszahlung Ihres Wunschbetrags',
    description:
      'Der Teilverkauf Ihrer Immobilie ist nun abgeschlossen. Der Auszahlungsbetrag für Ihren Immobilien-Teilverkauf wird auf Ihr angegebenes Konto überwiesen und steht Ihnen zeitnah zur Verfügung.',
    statusInfoPoints: [
      'Der Auszahlungsbetrag wird auf Ihr Konto überwiesen',
      'Ihr Immobilien-Teilverkauf ist abgeschlossen',
      'Ihr Teilkaufberater steht Ihnen weiterhin zur Seite',
    ],
    cta: { title: 'Zu Ihren Immobilienbereich', href: '/meineimmobilie' },
  },
};
