import { PricingTrend, Trend } from '@dtk/query';

export const calculateNumTicksAxisBottom = (width: number): number => {
  if (width > 1000) return 20;

  if (width > 400) return 10;

  return 5;
};

export const mapToChartData = (pricingTrend: PricingTrend, wohnflaeche: number): ChartTrend[] => {
  const pastValues = pricingTrend.past?.map((trend) => mapTrend(trend, wohnflaeche)) || [];
  const futureValues = pricingTrend.future?.map((trend) => mapTrend(trend, wohnflaeche)) || [];

  return [...pastValues, ...futureValues];
};

export const mapTrend = (trend: Trend, wohnflaeche: number): ChartTrend => {
  return {
    absolute: trend.absolute ? parseFloat(trend.absolute?.toFixed(0)) : undefined,
    relative: trend.relative ? parseFloat(trend.relative?.toFixed(2)) : undefined,
    year: new Date(trend.year as string),
    totalValuePerQM: trend.totalValue ? parseFloat((trend.totalValue / wohnflaeche).toFixed(0)) : 0,
  };
};

export const getPastAndFutureYears = (trends: PricingTrend) => {
  if (!trends) {
    return null;
  }

  const date = new Date().getFullYear();

  const currentYearPastIndex = trends?.past?.findIndex((item) => item.year === date.toString());
  const currentYearFutureIndex = trends?.future?.findIndex((item) => item.year === date.toString());

  const futureYearsFromCurrent = trends?.future?.slice(currentYearFutureIndex, 5);
  const pastYearsFromCurrent = trends?.past?.slice(currentYearPastIndex - 5);

  return { futureYearsFromCurrent, pastYearsFromCurrent };
};

export interface DisplayTrend {
  years: string;
  relative: number;
  absolute: number;
}

export type ChartTrend = {
  year: Date;
  relative: number | undefined;
  absolute: number | undefined;
  totalValuePerQM: number;
};
