import { Button } from '@dtk/ui-components';
import { useAppConfig } from '@dtk/config';

export function CustomerInitialStageContent() {
  const { config } = useAppConfig();
  const HEADLINE = 'Fordern Sie jetzt ein Teilverkauf-Angebot an!';
  const SUBLINE =
    'So erhalten Sie kostenlos eine individuelle und umfassende Immobilienbewertung sowie Zugang zu allen Informationen für Ihren möglichen Teilverkauf.';

  return (
    <>
      <img
        src="https://a.storyblok.com/f/148087/411x231/a5dc185989/popover_image.png/m/smart"
        className="h-[231px] max-h-[231px] w-full rounded-sm object-cover"
        alt="angebot anfragen"
        loading="eager"
        {...{ fetchpriority: 'high' }}
      />
      <h2 className="font-headline text-navy mt-4 text-2xl">{HEADLINE}</h2>
      <h3 className="text-navy-800 mt-4">{SUBLINE}</h3>
      <Button
        onClick={() => {
          window.open(`${config['WEBSITE_SOURCE']}/angebot-anfordern/`, '_blank');
        }}
        className="mt-4"
      >
        <span className="ml-2">Teilverkauf anfordern und freischalten</span>
      </Button>
    </>
  );
}
