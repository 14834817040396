import { ArrowUpIcon } from '@heroicons/react/outline';

import { formatCurrency, formatPercent } from '@dtk/formatter';
import { IconError, IconPropertyValue, IconRequestedPayout } from '@dtk/ui-components';
import { Immobilie, PricingTrend, ProposalEstateValueIncrease } from '@dtk/query';

import { DynamicComponent } from '../02_composite';
import { getValue, getTrendCurrentYear } from '../utils';

enum CARD_WITH_VALUE_TYPE {
  REQUESTED_PAYOUT = 'requestedPayout',
  PROPERTY_VALUE = 'propertyValue',
  ADDED_VALUE = 'addedValue',
  USAGE_CHARGE = 'usageCharge',
}

interface CardsContainerProps {
  blok: {
    _uid: string;
    cards: Record<string, unknown>[];
    component: string;
  };
  data: {
    estateData: Immobilie;
    trendData: PricingTrend;
    dtkData: ProposalEstateValueIncrease;
    isLead: boolean;
    estateError: boolean;
    trendError: boolean;
    dtkIndexError: boolean;
    valuePlaceholder: string;
    estateLoading: boolean;
    trendLoading: boolean;
    isIndexLoading: boolean;
  };
}

export function CardsContainer({ blok, data }: CardsContainerProps) {
  const { estateError, dtkIndexError, valuePlaceholder, estateLoading, isIndexLoading } = data;

  const requestedPayout = data?.estateData?.finanzielleDaten?.wunschauszahlung || 0;
  const totalPrice = data?.estateData?.finanzielleDaten?.geschaetzterImmobilienwert || 0;
  const livingSpace = data?.estateData?.basisdaten?.wohnflaeche || 0;
  const purchasePriceFrom = data?.estateData?.finanzielleDaten?.kaufpreisVon || 0;
  const purchaseUntill = data?.estateData?.finanzielleDaten?.kaufpreisBis || 0;
  const NUTZUNGSENTGELT = 0.0499;

  const trendCurrentYear = getTrendCurrentYear(data?.trendData);
  const squareMeterPrice = trendCurrentYear?.totalValue && livingSpace ? trendCurrentYear?.totalValue / livingSpace : 0;

  const getPropertyValue = () => {
    if (data?.isLead) {
      return formatCurrency(totalPrice, 0);
    }

    return `${formatCurrency(purchasePriceFrom, 0, false)} - ${formatCurrency(purchaseUntill, 0)}`;
  };

  const getSquareMeterPrice = () => {
    if (!livingSpace) {
      return `⌀ ${formatCurrency(0)}/m²`;
    }
    if (data?.isLead) {
      return `⌀ ${formatCurrency(totalPrice / livingSpace)}/m²`;
    }

    return `⌀ ${formatCurrency(squareMeterPrice, 0)}/m²`;
  };

  const getAPIValues = (type: CARD_WITH_VALUE_TYPE) => {
    const propertyValueFooter = data?.isLead ? totalPrice && livingSpace : squareMeterPrice;
    const propertyValue = data?.isLead ? totalPrice : purchasePriceFrom && purchaseUntill;

    switch (type) {
      case CARD_WITH_VALUE_TYPE.REQUESTED_PAYOUT:
        return {
          value: getValue(requestedPayout, valuePlaceholder, formatCurrency(requestedPayout, 0)),
          isError: estateError,
          isLoading: estateLoading,
        };
      case CARD_WITH_VALUE_TYPE.PROPERTY_VALUE:
        return {
          value: getValue(propertyValue, valuePlaceholder, getPropertyValue()),
          footer: getValue(propertyValueFooter, valuePlaceholder, getSquareMeterPrice()),
          isError: estateError,
          isLoading: estateLoading,
        };
      case CARD_WITH_VALUE_TYPE.ADDED_VALUE: {
        return {
          value: getValue(
            data?.dtkData?.increaseValuePercentage,
            valuePlaceholder,
            formatPercent(data?.dtkData?.increaseValuePercentage / 100, 2)
          ),
          valueIcon: data?.dtkData?.increaseValuePercentage && !dtkIndexError && (
            <ArrowUpIcon className="h-10 w-10 rotate-45 text-[#31C48D]" />
          ),
          isError: dtkIndexError,
          isLoading: isIndexLoading,
        };
      }
      case CARD_WITH_VALUE_TYPE.USAGE_CHARGE:
        return {
          value: getValue(
            requestedPayout,
            valuePlaceholder,
            formatCurrency((requestedPayout * NUTZUNGSENTGELT) / 12, 0)
          ),
          footer: `${formatPercent(NUTZUNGSENTGELT)} der Auszahlungssumme pro Jahr`,
          isError: estateError,
          isLoading: estateLoading,
        };
    }
  };

  const getCardIcon = (type: CARD_WITH_VALUE_TYPE) => {
    switch (type) {
      case CARD_WITH_VALUE_TYPE.REQUESTED_PAYOUT:
        return <IconRequestedPayout className="h-full w-full" />;
      case CARD_WITH_VALUE_TYPE.PROPERTY_VALUE:
        return <IconPropertyValue className="h-full w-full" />;
    }
    return <IconError width="100%" height="100%" />;
  };

  const formatCards = (card) => {
    switch (card.component) {
      case 'InfoCardWithValueIcon':
        return {
          ...getAPIValues(card.type),
          icon: getCardIcon(card.type),
          isLead: data.isLead,
          ...card,
        };
      case 'DoubleInfoCardWithValue':
        return {
          ...card,
          isLoading: estateLoading || isIndexLoading,
          cards: card.cards.map((storyblokCard) => ({ ...storyblokCard, ...getAPIValues(storyblokCard.type) })),
        };
      default:
        return card;
    }
  };

  return (
    <div className="grid grid-cols-12 gap-6">
      {blok?.cards?.map((card) => {
        return <DynamicComponent key={card._uid as number} blok={formatCards(card)} data={data} />;
      })}
    </div>
  );
}
