import { useGetContactData } from '@dtk/query';
import { StoryblokContact, useStory } from '@dtk/storyblok';
import { CardSize, ContactCard, QueryStatusCard, getStoryblokTheme } from '@dtk/ui-components';
import { ContactCardFallback } from '../02_composite';

const CARD_TITLE = 'Ihr persönlicher Ansprechpartner';

export function DashboardContactCard({ blok }) {
  const { data: sfContact } = useGetContactData();

  const { primaryTextColor, secondaryTextColor, primaryBgColor, secondaryBgColor } = getStoryblokTheme(blok?.theme);

  return (
    <div className="xs:col-span-12 col-span-12 min-h-[458px] rounded-lg md:min-h-[280px] lg:col-span-6 lg:min-h-[368px]">
      <DashboardContactCardBlok
        slug={getSlug(sfContact?.data?.eMail)}
        primaryTextColor={primaryTextColor}
        primaryBgColor={primaryBgColor}
        secondaryBgColor={secondaryBgColor}
        secondaryTextColor={secondaryTextColor}
      />
    </div>
  );
}

function DashboardContactCardBlok({
  slug,
  primaryTextColor,
  secondaryTextColor,
  primaryBgColor,
  secondaryBgColor,
}: {
  slug: string;
  primaryTextColor: string;
  secondaryTextColor: string;
  primaryBgColor: string;
  secondaryBgColor: string;
}) {
  const { story, loading, error } = useStory<StoryblokContact>({ content: 'advisor', slug });

  const firstname = story?.content?.firstname;
  const lastname = story?.content?.lastname;
  const eMail = story?.content?.email;
  const phone = story?.content?.phone;
  const pictureUrl = story?.content?.landscape_image?.filename;

  if (error) {
    return (
      <ContactCardFallback
        primaryTextColor={primaryTextColor}
        svgPrimaryColor={secondaryTextColor}
        primaryBgColor={primaryBgColor}
        secondaryBgColor={secondaryBgColor}
      />
    );
  }

  if (loading) {
    return <QueryStatusCard title={CARD_TITLE} isLoading={true} cardSize={CardSize.MEDIUM} />;
  }

  if (!eMail || !firstname || !phone) {
    return <QueryStatusCard title={CARD_TITLE} state={'noDataAvailable'} cardSize={CardSize.MEDIUM} />;
  }

  return (
    <ContactCard
      firstname={firstname}
      lastname={lastname}
      eMail={eMail}
      phone={phone}
      pictureUrl={pictureUrl}
      primaryTextColor={primaryTextColor}
      secondaryBgColor={secondaryBgColor}
      svgPrimaryColor={secondaryTextColor}
      primaryBgColor={primaryBgColor}
    />
  );
}

function getSlug(eMail: string): string {
  if (eMail) {
    return eMail.split('@')[0];
  }
  return 'default';
}
