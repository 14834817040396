import { ColorPickerBlok } from '@dtk/types';
import { CardSize, InfoCard, QueryStatusCard, Theme, TooltipColor, getStoryblokTheme } from '@dtk/ui-components';
import classNames from 'classnames';

export interface InfoCardWithValueIconProps {
  blok: {
    title: string;
    titleColor: ColorPickerBlok;
    backgroundColor?: ColorPickerBlok;
    value: number;
    footer?: string;
    tooltipText: string;
    tooltipColorVariant: TooltipColor;
    tooltipIconColor: ColorPickerBlok;
    isLead: boolean;
    icon: React.ReactNode;
    isError: boolean;
    isLoading: boolean;
    theme: Theme;
  };
}

export function InfoCardWithValueIcon({ blok }: InfoCardWithValueIconProps) {
  const {
    primaryTextColor,
    primaryBgColor,
    secondaryTextColor,
    tertiaryTextColor,
    infoIconColor,
    infoIconTooltipColorVariant,
  } = getStoryblokTheme(blok?.theme);

  const getContainerStyle = () =>
    classNames(
      'xs:min-h-[161px] rounded-lg shadow-lg lg:min-h-[368px] md:min-h-[320px] xs:min-h-[322px] xs:col-span-12 col-span-12 rounded-lg md:col-span-6 lg:col-span-6 xl:col-span-3 '
    );

  if (blok?.isLoading) {
    return <QueryStatusCard title={blok?.title} tooltipText={blok?.tooltipText} isLoading={true} />;
  }

  if (blok?.isError) {
    return (
      <QueryStatusCard
        title={blok?.title}
        tooltipText={blok?.tooltipText}
        state={'tryAgainLater'}
        cardSize={CardSize.SMALL}
      />
    );
  }

  return (
    <InfoCard
      title={blok?.title}
      titleColor={primaryTextColor}
      tooltipText={blok?.tooltipText}
      tooltipColorVariant={infoIconTooltipColorVariant as TooltipColor}
      tooltipIconColor={infoIconColor}
      containerStyle={getContainerStyle()}
      backgroundColor={primaryBgColor}
    >
      <div
        className={classNames('text-[40px] font-bold leading-none', secondaryTextColor && `text-${secondaryTextColor}`)}
      >
        {blok?.value}
      </div>
      {blok?.footer && (
        <div className={classNames('text-lg', tertiaryTextColor && `text-${tertiaryTextColor}`)}>{blok.footer}</div>
      )}
      <div className="flex flex-1 items-end justify-center">
        <div className="xs:h-[138px] xs:w-[126px] xs:mb-[17px] md:h-[138px] md:w-[126px] lg:mb-[50px] lg:h-[159px] lg:w-[174px]">
          {blok?.icon}
        </div>
      </div>
    </InfoCard>
  );
}
