import { useGetCustomer } from '@dtk/query';
import { GreetingsHeroSection, GreetingsHeroSectionProps, defaultData } from '@dtk/ui-components';

export function DashboardHeroSectionWrapper({ externalData }: GreetingsHeroSectionProps) {
  const { data, isLoading, isError } = useGetCustomer();

  if (isLoading || isError || !externalData) {
    return <GreetingsHeroSection externalData={defaultData} />;
  }

  const customer = data?.data;
  return (
    <GreetingsHeroSection
      externalData={externalData}
      customerSalutation={customer.salutation}
      customerLastname={customer.lastname}
    />
  );
}
