import { useGetCustomer, useGetProposalEstateValueIncrease, useGetSocioDemographics } from '@dtk/query';
import {
  CardSize,
  ErrorState,
  Info,
  QueryStatusCard,
  TooltipColor,
  Visible,
  getStoryblokTheme,
  tailwindClassToThemeValue,
} from '@dtk/ui-components';
import { Disclosure } from '@headlessui/react';
import { ChevronDownIcon } from '@heroicons/react/solid';

import classNames from 'classnames';
import get from 'lodash.get';
import { Scoring } from './Scoring';
import { PropertyMapArea } from '../02_composite/PropertyMapArea';

export function DashboardDTKIndex({ blok, data: { isLead } }) {
  const { data: customer, isLoading: isCustomerLoading, isError: isCustomerError } = useGetCustomer();
  const { data: dtk, isLoading: isIndexLoading, isError: isIndexError } = useGetProposalEstateValueIncrease();
  const { data: socio, isLoading: isSocioLoading, isError: isSocioError } = useGetSocioDemographics();

  const dtkIndex = dtk?.data.dtkIndex;
  const maxDtkIndex = dtk?.data?.maxDtkIndex;

  const cardSize = isLead ? CardSize.LARGE : CardSize.MEDIUM;

  const {
    primaryTextColor,
    secondaryTextColor,
    primaryBgColor,
    primaryTextColorComplementary,
    infoIconColor,
    infoIconTooltipColorVariant,
  } = getStoryblokTheme(blok?.theme);

  const getDTKIndexLines = () => {
    const lines = [];
    for (let index = 1; index <= maxDtkIndex; index++) {
      lines.push(
        <div
          key={index}
          style={{
            backgroundColor:
              dtkIndex >= index
                ? tailwindClassToThemeValue(secondaryTextColor)
                : tailwindClassToThemeValue(primaryTextColor),
          }}
          className="h-full w-[36px]"
        />
      );
    }
    return lines;
  };

  const tileTheme = 'https://tiles.stadiamaps.com/tiles/alidade_smooth/{z}/{x}/{y}{r}.png';

  if (dtk?.data.error === 'ENTITY_NOT_FOUND') {
    return null;
  }

  if (isCustomerError || isIndexError || isSocioError) {
    return (
      <QueryStatusCard
        title={blok?.title}
        tooltipText={blok?.tooltipText}
        cardSize={cardSize}
        state={'tryAgainLater'}
      />
    );
  }

  if (isSocioLoading || isIndexLoading) {
    return <QueryStatusCard title={blok?.title} tooltipText={blok?.tooltipText} isLoading={true} cardSize={cardSize} />;
  }

  if (!socio?.data || !dtk?.data) {
    return (
      <QueryStatusCard
        title={blok?.title}
        tooltipText={blok?.tooltipText}
        state={'noDataAvailable'}
        cardSize={cardSize}
      />
    );
  }

  return (
    <div
      className={classNames(
        'col-span-12 lg:col-span-6',
        'xs:min-h-[742px] flex min-h-[742px] flex-col md:min-h-[644px] lg:min-h-[760px]'
      )}
    >
      <div className="min-h-[67px] w-full items-center rounded-t-lg bg-white text-lg">
        <div className="mx-6 mt-6 flex justify-between">
          <div
            className={classNames(
              'font-semibold',
              primaryTextColorComplementary && `text-${primaryTextColorComplementary}`
            )}
          >
            {blok?.title}
          </div>
          <div className={infoIconColor ? `text-${primaryTextColorComplementary}` : ''}>
            <Info
              content={blok?.tooltipText}
              variant="tooltip"
              colorVariant={infoIconTooltipColorVariant as TooltipColor}
            />
          </div>
        </div>
      </div>

      <div className="flex h-full min-h-[716px] w-full flex-col">
        <Visible when={Boolean(dtkIndex && maxDtkIndex)}>
          <div
            className={classNames(
              'xs:min-h-[110px] min-h-[110px] w-full md:min-h-[160px] lg:min-h-[168px]',
              primaryBgColor && `bg-${primaryBgColor}`,
              primaryTextColor && `text-${primaryTextColor}`
            )}
          >
            <div className="flex h-full w-full flex-col justify-center px-4 pt-4 pb-2 lg:px-5 lg:pt-5 lg:pb-2">
              <div className="relative flex h-full w-full items-center justify-between">
                <div className="absolute top-0 left-0 z-10 flex items-end md:static lg:static">
                  <div
                    className={classNames(
                      'text-6xl font-bold md:text-8xl lg:text-8xl',
                      secondaryTextColor && `text-${secondaryTextColor}`
                    )}
                  >
                    {dtkIndex}
                  </div>
                  <div
                    className={classNames(
                      'ml-2.5 text-2xl lg:text-3xl',
                      primaryTextColor && `text-${primaryTextColor}`
                    )}
                  >
                    von {maxDtkIndex}
                  </div>
                </div>
                <div className="relative flex h-full w-full justify-end overflow-hidden md:w-auto lg:w-auto">
                  <div
                    className={classNames(
                      'absolute top-[-50%] h-full w-full',
                      primaryBgColor && `bg-${primaryBgColor}`
                    )}
                    style={{ clipPath: 'ellipse(100% 98% at 0% 0%)', transform: 'scale(1.4,2)' }}
                  />
                  <div className="flex h-full gap-2">{getDTKIndexLines()}</div>
                </div>
              </div>
            </div>
          </div>
          <div className={classNames('w-full', primaryBgColor && `bg-${primaryBgColor}`)}>
            <div className="border-coolGray-500 mx-5 border" />
          </div>
        </Visible>

        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button
                className={classNames(
                  'flex min-h-[48px] w-full justify-between px-4 py-2 text-base font-medium',
                  primaryBgColor && `bg-${primaryBgColor}`,
                  primaryTextColor && `text-${primaryTextColor}`
                )}
              >
                <div>{blok?.footer}</div>
                <ChevronDownIcon className={`${open ? 'rotate-180 transform' : ''} h-5 w-5`} />
              </Disclosure.Button>

              <Disclosure.Panel
                className={classNames(
                  'h-full w-full rounded-b-lg px-4 pt-4 pb-2 text-sm',
                  primaryBgColor && `bg-${primaryBgColor}`
                )}
              >
                <Scoring
                  socioData={socio?.data}
                  primaryTextColor={primaryTextColor}
                  secondaryTextColor={secondaryTextColor}
                  tooltips={get(blok, ['tooltips', 0, 'tooltips'])}
                />
              </Disclosure.Panel>

              <Visible
                when={Boolean(!isCustomerLoading && customer?.data?.street && customer?.data?.postCode)}
                renderIf={
                  <div className="flex-1">
                    <PropertyMapArea
                      style={{ borderBottomLeftRadius: '0.5rem', borderBottomRightRadius: '0.5rem' }}
                      address={`${customer?.data?.street} ${customer?.data?.postCode}`}
                      tileTheme={tileTheme}
                    />
                  </div>
                }
                renderElse={
                  <div className={`flex ${!open ? 'h-full' : ''} items-center justify-center shadow-lg`}>
                    <Visible when={!open}>
                      <ErrorState state={'noDataAvailable'} />
                    </Visible>
                  </div>
                }
              />
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
}
